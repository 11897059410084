import React from "react";
import successStories from "../../Data/Service";
function ServiceSection() {
  return (
    <div className="sectionlayout ">
      <p
        className=" text-justify py-12"
        style={{ fontSize: "clamp(18px,1.2vw,24px)" }}
      >
    We saw beyond conventional lighting—transforming how spaces are experienced through cutting-edge technology and human-centric design. Our mission transcends mere illumination, crafting sustainable solutions that speak to aesthetic elegance and environmental responsibility.
      </p>

      <div className="my-20">
        <h2
          className=" font-bold text-center text-black mb-8 source-serif"
          style={{ fontSize: "clamp(26px,1.6vw,32px)" }}
          data-aos="fade-up"
        >
          Services
        </h2>

        <div className="relative flex justify-center mt-16">
          <div className="w-[2px] bg-gray-300 absolute md:block hidden h-full top-0"></div>
          <div className="relative">
            {successStories.map((story, index) => (
              <div
                key={index}
                className="mb-16 relative flex flex-col md:flex-row items-center"
              >
                <span
                  className="absolute top-[-8px] w-3 h-3 md:block hidden rounded-full bg-[#FFD700] transform -translate-x-1.5"
                  style={{ left: "50%" }}
                ></span>

                <div
                  className={`flex flex-col-reverse w-full md:flex-row ${
                    index % 2 !== 0
                      ? "flex-col-reverse md:flex-row-reverse justify-end"
                      : "justify-start"
                  }`}
                  data-aos="fade-up"
                >
                  <div
                    className={`md:w-1/2  md:flex  ${
                      index % 2 !== 0 ? "justify-end " : "justify-start"
                    }`}
                  >
                    <div className="md:w-[90%] mt-3 md:mt-0">
                      <h1 className="font-bold mb-2 "
                         style={{ fontSize: "clamp(27px,2.7vw,38px)" }}
                      >
                        <span className="text-black">
                          {story.title.split(" ")[0]}
                        </span>{" "}
                        <span className="text-[#FFD700]">
                          {story.title.split(" ")[1]}
                        </span>{" "}
                        <span className="text-black">
                          {story.title.split(" ")[2]}
                        </span>
                      </h1>

                      <h2 className="text-[#1E1E1E] font-bold mb-2 " 
                        style={{ fontSize: "clamp(16px,0.8vw,16px)" }}
                        >
                        {story.category}
                      </h2>
                      <p className="text-[#1E1E1E] text-justify"
                        style={{ fontSize: "clamp(16px,0.8vw,16px)" }}
                      >{story.description}</p>
                    </div>
                  </div>
                  <div
                    className={`w-full  md:w-1/2  md:flex  ${
                      index % 2 !== 0 ? "justify-start " : "justify-end"
                    }`}
                  >
                    <div className="w-full md:w-[90%]">
                      <img
                        src={story.img}
                        alt={story.title}
                        className="rounded-lg w-full object-cover"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceSection;
