import React from "react";
function Content() {
  return (
    <div className="py-8 overflow-x-hidden">
      <div className="sectionlayout ">
        <div className="flex md:flex-row flex-col-reverse  md:space-x-6  ">
          <div className="w-full md:w-1/2 mt-8 md:mt-0" data-aos="fade-right">
            <h1 style={{ fontSize: "clamp(22px,2vw,35px)", fontWeight: "bold" }}>
              Our Story-Designing Light & Crafting Experiences
            </h1>

            <p
  className="mt-3"
  style={{
    fontSize: "clamp(18px, 1.1vw, 24px)",
    textAlign: "justify",
  }}
>
  Illuminex Lighting was born from a singular vision: to transform spaces through the art of light. What began as a passionate dream has evolved into a pioneering lighting design journey that reimagines how environments can be experienced.
  Our story is not just about creating light—it's about crafting experiences. From our first custom chandelier to complex projects, we've remained committed to a philosophy that sees light as a medium of expression and transformation.
</p>

          </div>
          <div
            className="w-full md:w-1/2 flex justify-center md:justify-end"
            data-aos="fade-left"
          >
            <img src={`${process.env.PUBLIC_URL}/Assets/about/best_lighting_services_uae.png`} alt="About Us" className="max-h-[70vh]" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Content;
