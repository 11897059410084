
const Service = [
  {
    title: "Architectural Lighting",
    category: "Sculpting Spaces with Luminous Precision",
    description:
      "Picture a space where every beam is a brushstroke, every shadow a carefully crafted emotion. We don't just illuminate rooms; we breathe life into architectural dreams. From the sleek lines of Dubai's iconic skyscrapers to the timeless elegance of Abu Dhabi's landmarks, our lighting solutions transform the UAE's architectural landscape into a canvas of luminous wonder. We don't just light up buildings – we celebrate the spirit of the Emirates, telling untold narratives one luminous moment at a time, turning each structure into a beacon of innovation and extraordinary vision.",
    img: `${process.env.PUBLIC_URL}/Assets/Services/Architectural_Lighting_Uae.jpg`
  },
  {
    title: "Facade Lighting",
    category: "Igniting Architectural Identities",
    description:
      "Where light meets architecture, we create visual symphonies that transform ordinary facades into extraordinary statements. From Dubai's iconic skyscrapers to elegant residential developments, we paint buildings with light, turning structural surfaces into dynamic canvases that breathe, communicate, and captivate.Picture a building that doesn't just exist, but tells a story—where every beam reveals its unique personality, and every shadow adds depth to its narrative. We don't just light facades; we give them a voice, celebrating architectural brilliance through innovative technologies.",

    img:`${process.env.PUBLIC_URL}/Assets/Services/Facade_Lighting_uae.jpg`
  },
  {
    title: "Landscape Lighting",
    category: "Transforming Outdoor Environments",
    description:
      "When nature meets innovation, we choreograph light to breathe life into outdoor landscapes. From desert gardens to urban parks, our landscape lighting solutions transform exterior spaces into breathtaking visual experiences that transcend ordinary illumination.Imagine landscapes that come alive after sunset—where every tree, pathway, and architectural element tells a story through carefully designed light. We don't just illuminate outdoor spaces; we create nocturnal symphonies that reveal the hidden beauty of natural and built environments.",
    img: `${process.env.PUBLIC_URL}/Assets/Services/Landscape_Lighting_uae.jpg`
  },
  {
    title: "Technical Lighting",
    category: "Engineering Precision Illumination",
    description:
      "Technology and illumination converge to transform operational spaces into intelligent, efficient ecosystems. From high-tech data centers to complex industrial environments, our technical lighting solutions are strategic performance enhancers.Imagine lighting that thinks, adapts, and optimizes—turning every technical space into a hub of productivity and innovation. We engineer comprehensive illumination strategies that maximize performance and reduce energy consumption.",
    img: `${process.env.PUBLIC_URL}/Assets/Services/Technical_Lighting_uae.jpg`
  },
  {
    title: "Urban Lighting",
    category: "Transforming City Landscapes",
    description:
      "Cities breathe through light, and we are the architects of urban illumination. From bustling Dubai streets to serene municipal plazas, our urban lighting solutions create living, breathing urban experiences that enhance safety, beauty, and community connection.Imagine cities that glow with intelligence, where every street and park tells a story of innovation. We design urban ecosystems that transform how people experience their environment, making every urban moment safer and more beautiful.",
    img:`${process.env.PUBLIC_URL}/Assets/Services/Urban_Lighting_uae.jpg`
  },
  {
    title: "Lighting Design Services",
    category: "Orchestrating Radiant Experiences",
    description:
      "Light becomes art when expertise meets innovation. Our lighting design services combine artistic intuition with engineering precision, creating bespoke solutions through Dialux Lux Level Calculations and detailed 3D Renderings. From conceptual development to precise lighting design execution, each beam of light is positioned with intent, turning lighting challenges into opportunities for extraordinary visual brilliance. We don't just design lighting systems; we create experiences that enhance spatial dynamics and human interactions through advanced modeling and innovative technology.",
    img: `${process.env.PUBLIC_URL}/Assets/Services/lighting_design_services_uae.jpeg`
  },
  {
    title: "Lighting Control System",
    category: "Elevate Your Space with Intelligent Lighting ",
    description:
      "Discover the transformative power of advanced lighting control systems that elevate your space. Our solutions range from sleek, architectural light switches to remote control and intelligent motion sensors, offering customizable lighting scenes and energy-saving controls tailored to your project’s needs. Whether for residential homes, luxurious hotels, or contemporary offices, our systems enhance your lighting environment, creating the perfect ambiance while making your space smarter and more energy-efficient.",
    img: `${process.env.PUBLIC_URL}/Assets/Services/Lighting_Control_Systems_uae.jpeg`
  },


];

export default Service;
