import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Social from '../../Components/Social/Social';

const Footer = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      offset: 100,
    });
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const navigationLinks = [
    { path: '/', label: 'Home' },
    { path: '/services', label: 'Services' },
    { path: '/about', label: 'About Us' },
    { path: '/contact', label: 'Contact Us' },
  ];

  return (
    <footer className="w-full bg-white py-5">
      <div className="w-full sectionlayout poppins">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 text-[#0A142F] py-8 gap-8">
          {/* Logo and Social Media */}
          <div data-aos="fade-up">
            <Link to="/" onClick={handleScrollToTop}>
              <img
                src={`${process.env.PUBLIC_URL}/Assets/logo/illuminex_lighting_company_uae.jpg`}
                alt="Illuminex Lighting"
                className="max-h-[9vh] max-w-[70%] md:max-h-[60px] md:max-w-[220px]"
              />
            </Link>
            <div className="flex md:block my-6">
              <Social />
            </div>
          </div>

          {/* Navigation Links */}
          <nav data-aos="fade-up" data-aos-offset="50">
            {navigationLinks.map(({ path, label }) => (
              <Link 
                key={path} 
                to={path} 
                onClick={handleScrollToTop}
                className="block mt-5 first:mt-0 text-[15px] hover:text-[#CC890E] transition-all duration-300 ease-in-out hover:underline decoration-[#CC890E]"
              >
                {label}
              </Link>
            ))}
          </nav>

          {/* Contact Information */}
          <div data-aos="fade-up" data-aos-offset="50">
          <div className="w-full flex items-center pt-2.5">
                <img src={`${process.env.PUBLIC_URL}/Assets/socialmediaicons/email.svg`} alt="Email" className="w-[1.7rem]" />
                <a
                  href="https://mail.google.com/mail/?view=cm&fs=1&to=info@illuminexlighting.ae&su=Inquiry&body=Hello%20Illuminex%20Lighting%20Team,"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="pl-2 md:pl-4 pt-2 text-black text-[17px] md:text-[20px] hover:underline"
                >
                  info@illuminexlighting.ae
                </a>
              </div>

            <div className="flex items-center gap-4 mt-5">
              <img 
                src={`${process.env.PUBLIC_URL}/Assets/socialmediaicons/phone.svg`} 
                alt="" 
                className="w-7"
                aria-hidden="true"
              />
              <div className="text-[17px]">
                <a href="tel:+971509969258" className="block hover:underline">+971 50 996 9258</a>
                <a href="tel:+971506367409" className="block hover:underline">+971 50 636 7409</a>
              </div>
            </div>

            <div className="flex items-start gap-4 mt-5">
              <img 
                src={`${process.env.PUBLIC_URL}/Assets/socialmediaicons/location.svg`} 
                alt="sharjah" 
                className="w-7 mt-1 font-size:18px"
                aria-hidden="true"
              />
              <a
                href="https://maps.google.com/?q=SHARJAH+RESEARCH+TECHNOLOGY+AND+INNOVATION+PARK"
                target="_blank"
                rel="noopener noreferrer"
                className="text-[15px] hover:underline"
              >
                SHARJAH RESEARCH TECHNOLOGY AND INNOVATION PARK
              </a>
            </div>
          </div>

          {/* Call to Action */}
          <div data-aos="fade-up" data-aos-offset="50" className="flex items-center lg:justify-end">
            <Link to="/contact" onClick={handleScrollToTop}>
              <button className="w-[202px] h-[50px] border-[#CC890E] text-[#CC890E] border rounded-[10px] font-semibold hover:bg-[#CC890E] hover:text-white transition-all duration-300 ease-in-out">
                Schedule a Call
              </button>
            </Link>
          </div>
        </div>

        <p className="text-[#6c6c6c] text-xl text-center mt-4">
  © 2024 Illuminex Lightning Solutions. All rights reserved
</p>
      </div>
    </footer>
  );
};

export default Footer;