import React from 'react'
import Hero from './Hero'
import Content from './Content'
import { Helmet } from 'react-helmet'

function About() {
  return (
    <>
      <Helmet>
        <title>Best Lighting Design Company Dubai | Illuminex UAE</title>
        <meta name="description" content="UAE's premier lighting design experts delivering innovative solutions across Dubai. Experience excellence in architectural & smart lighting control systems." />
        <meta name="keywords" content="lighting design dubai, architectural lighting uae, smart lighting controls, lighting design company dubai, illuminex uae, lighting solutions dubai" />
      </Helmet>
      
      <div>
        <Hero />
        <Content />
      </div>
    </>
  )
}

export default About