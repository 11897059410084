import './Navbar.css';
import React, { useEffect, useState, useRef } from "react";
import { TfiClose } from "react-icons/tfi";
import { BsChevronDown } from "react-icons/bs";
import { FaBars } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import Social from '../Social/Social';
import Navbar from '../../Data/Navbar';

function Mobile() {
    const [nav, setNav] = useState(false);
    const [solutionsDropdown, setSolutionsDropdown] = useState(false);

    const handleNav = () => {
        setNav((prevNav) => !prevNav);
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
    };
    const handleCrossNav = () => {
        setNav((prevNav) => !prevNav);
    };

    const handleLinkClick = () => {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
    };

    const sidebarRef = useRef(null);
    const handleClickOutside = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
            setNav(false);
        }
    };

    useEffect(() => {
        window.addEventListener("click", handleClickOutside);
        return () => {
            window.removeEventListener("click", handleClickOutside);
        };
    }, [nav]);

    return (
        <>
            <div className="lg:hidden sticky top-0 bg-white h-24 flex items-center w-full z-[10000]">
                <div className="sectionlayout poppins text-[#6C6C6C] h-[10vh] flex justify-between items-center w-full">
                    <div className='flex items-center justify-center'>
                        <NavLink to="/" onClick={handleLinkClick}>
                            <img src={`${process.env.PUBLIC_URL}/Assets/logo/illuminex_lighting_company_uae.jpg`} className='max-h-[9vh] max-w-[80%]' alt="It-Solutions-Dynamic-Leo" />
                        </NavLink>
                    </div>
                    <div className="flex items-center">
                        <button
                            className="inline-flex items-center rounded-md hover:text-[#99702B] ease-in-out transition-all duration-200 py-2 px-4 p-2 text-lg font-medium"
                            onClick={handleCrossNav}
                        >
                            <FaBars
                                size={20}
                                className="text-[#454545] drop-shadow-md cursor-pointer hover:text-[#99702B] ease-in-out transition-all duration-200"
                            />
                        </button>
                    </div>
                    <div className={`navbar-menu ${nav ? 'open' : ''}`}>
                        <div className="sticky bg-white shadow-xl border-l border-white/70 overflow-y-auto">
                            <ul className="pt-4 px-6 pb-3 overflow-y-auto h-[100vh]">
                                <div className="flex justify-end pt-4">
                                    <TfiClose
                                        onClick={handleCrossNav}
                                        className="rounded p-1 font-bold hover:bg-black/5"
                                        size={24}
                                    />
                                </div>
                                <div className="mt-6">
                                    {Navbar.links.map((link, index) => (
                                        <React.Fragment key={index}>
                                            {link.submenu ? (
                                                <>
                                                    <div className="cursor-pointer px-1 flex items-center justify-between Georgian py-3 pl-3 font-medium hover:rounded hover:bg-black/5">
                                                        <NavLink
                                                            to={link.path}
                                                            className={({ isActive }) =>
                                                                ` ${isActive ? 'text-[#B19361]' : 'text-[#6C6C6C]'}`
                                                            }
                                                        >
                                                            {link.name}
                                                        </NavLink>
                                                        <BsChevronDown
                                                            onClick={() => setSolutionsDropdown(!solutionsDropdown)}
                                                            className={`${solutionsDropdown && "rotate-180 flex items-center font-bold"}`}
                                                        />
                                                    </div>
                                                    {solutionsDropdown && (
                                                        <div className={`submenu ${solutionsDropdown ? 'open' : ''} mt-2 ml-4 flex max-h-[30vh] overflow-y-auto flex-col justify-center my-3`}>
                                                            <ul className="overflow-y-auto relative flex flex-col">
                                                                {link.submenu.map((sublink, subIndex) => (
                                                                    <NavLink
                                                                        to={sublink.path}
                                                                        key={subIndex}
                                                                        className={({ isActive }) =>
                                                                            `text-[#454545] text-md items-center gap-x-4 cursor-pointer p-5 rounded-md py-4 pl-3 hover:rounded hover:bg-black/5 ${isActive ? 'text-[#B19361]' : 'text-[#6C6C6C]'}`
                                                                        }
                                                                        onClick={handleNav}
                                                                    >
                                                                        {sublink.name}
                                                                    </NavLink>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    )}
                                                </>
                                            ) : (
                                                <NavLink
                                                    to={link.path}
                                                    className={({ isActive }) =>
                                                        `cursor-pointer px-1 flex items-center justify-between Georgian py-3 pl-3 font-medium hover:rounded hover:bg-black/5 ${isActive ? 'text-[#B19361]' : 'text-[#6C6C6C]'}`
                                                    }
                                                    onClick={handleNav}
                                                >
                                                    {link.name}
                                                </NavLink>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </div>
                                <div className='w-56 z-10 absolute bottom-10'>
                                    <div className='flex items-center justify-center flex-col'>
                                        <div className='flex justify-center items-center mb-5'>
                                            <img src={`${process.env.PUBLIC_URL}/Assets/logo/illuminex_lighting_company_uae.jpg`} alt="It-Solutions-Dynamic-Leo" className='min-w-[70%] max-h-[20vh] pl-4' />
                                        </div>
                                        <div>
                                            <Social />
                                        </div>
                                    </div>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Mobile;
