import React from 'react'
import Hero from './Hero'
import ServiceSection from './ServiceSection'
import ContactForm from './ContactForm'
import { Helmet } from 'react-helmet'

function Services() {
  return (
    <>
      <Helmet>
        <title>Lighting Design & Control Solutions Dubai | Illuminex UAE</title>
        <meta name="description" content="Expert lighting services in Dubai - architectural, facade, landscape, control systems & smart automation. UAE's trusted provider of innovative lighting solutions." />
        <meta name="keywords" content="lighting design dubai, lighting control solutions, architectural lighting, facade lighting, landscape lighting, smart automation dubai, illuminex uae" />       
      </Helmet>
      
      <div>
        <Hero />
        <ServiceSection />
        <ContactForm />
      </div>
    </>
  )
}

export default Services