import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function Contact() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const locationUrl = "https://maps.google.com?q=Sharjah+Research+Technology+and+Innovation+Park";

  const handleSubmit = (event) => {
    event.preventDefault();
    alert("Message sent successfully!");
  };

  return (
    <div className="w-full overflow-hidden relative">
      <div
        className="w-full sectionlayout bg-cover"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/Assets/contact/contact_illuminex_custom_lighting.png)`,
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="flex justify-center">
          <h2
            className="uppercase mt-6 text-center p-4 sm:p-8 lg:p-12 bg-[#5C626866] rounded-lg text-white font-bold pt-[2.9rem]"
            style={{ fontSize: "clamp(50px,4vw,64px)" }}
            data-aos="fade-up"
          >
            Contact US
          </h2>
        </div>
        <div className="md:flex py-[3rem] md:py-[5rem] border-b">
          {/* Contact Info */}
          <div
            className="md:w-1/2 mb-[3rem] md:mb-0 flex items-center justify-start md:items-start"
            data-aos="fade-up"
          >
            <div className="w-[97%] md:w-auto]" data-aos="fade-right">
              <p
                style={{ fontSize: "clamp(30px,3vw,45px)" }}
                className="text-white font-bold tracking-wide uppercase"
              >
                Get in Touch with Us
              </p>
              {/* Phone Section */}
              <div className="w-full flex items-center pt-10">
                <img src={`${process.env.PUBLIC_URL}/Assets/socialmediaicons/phone.svg`} alt="Phone Icon" className="w-[1.7rem]" />
                <a href="tel:+971509969258" className="pl-2 md:pl-4 pt-2 text-white text-[17px] md:text-[20px] hover:underline">
                  +971 50 996 9258
                </a>
              </div>
              <div className="w-full flex items-center pt-2">
                <img src={`${process.env.PUBLIC_URL}/Assets/socialmediaicons/phone.svg`} alt="Phone Icon" className="w-[1.7rem] opacity-0" />
                <a href="tel:+971506367409" className="pl-2 md:pl-4 text-white text-[17px] md:text-[20px] hover:underline">
                  +971 50 636 7409
                </a>
              </div>

              {/* Email Section */}
              <div className="w-full flex items-center pt-2.5">
                <img src={`${process.env.PUBLIC_URL}/Assets/socialmediaicons/email.svg`} alt="Email" className="w-[1.7rem]" />
                <a
                  href="https://mail.google.com/mail/?view=cm&fs=1&to=info@illuminexlighting.ae&su=Inquiry&body=Hello%20Illuminex%20Lighting%20Team,"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="pl-2 md:pl-4 pt-2 text-white text-[17px] md:text-[20px] hover:underline"
                >
                  info@illuminexlighting.ae
                </a>
              </div>

              {/* Location Section */}
              <div className="w-full flex items-center pt-2.5">
                <img src={`${process.env.PUBLIC_URL}/Assets/socialmediaicons/location.svg`} alt="Location Icon" className="w-[1.7rem]" />
                <a
                  href={locationUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="pl-2 md:pl-4 pt-2 text-white text-[17px] md:text-[20px] hover:underline"
                >
                  SHARJAH RESEARCH TECHNOLOGY AND INNOVATION PARK
                </a>
              </div>
            </div>
          </div>

          {/* Form Section */}
            {/* Form Section */}
            <div
            className="md:w-1/2 flex items-center md:justify-end relative mt-[6.5rem] md:mt-0"
            data-aos="fade-left"
          >
            <div
              className="bg-white shadow-xl inline-block w-[97%] md:w-[80%] rounded-xl"
              data-aos="fade-up"
            >
              <form className="px-4 md:px-8 py-12">
                <div className="lg:flex gap-4">
                  <input
                    type="text"
                    placeholder="First Name"
                    required
                    className="w-full p-3 md:p-4 rounded-xl border text-[#828282] hover:outline-[#CC890E] focus:outline focus:outline-[#CC890E] focus:outline-2 mb-6 lg:mb-0"
                  />
                  <input
                    type="text"
                    placeholder="Last Name"
                    required
                    className="w-full p-3 md:p-4 rounded-xl border text-[#828282] hover:outline-[#CC890E] focus:outline focus:outline-[#CC890E] focus:outline-2"
                  />
                </div>
                <div className="pt-6">
                  <input
                    type="email"
                    placeholder="Email"
                    required
                    className="w-full p-3 md:p-4 rounded-xl border text-[#828282] hover:outline-[#CC890E] focus:outline focus:outline-[#CC890E] focus:outline-2"
                  />
                </div>
                <div className="pt-6">
                  <input
                    type="tel"
                    placeholder="Phone"
                    required
                    className="w-full p-3 md:p-4 rounded-xl border text-[#828282] hover:outline-[#CC890E] focus:outline focus:outline-[#CC890E] focus:outline-2"
                  />
                </div>
                <div className="pt-6">
                  <textarea
                    placeholder="Your Message"
                    rows="6"
                    required
                    className="w-full p-3 md:p-4 rounded-xl border text-[#828282] hover:outline-[#CC890E] focus:outline focus:outline-[#CC890E] focus:outline-2"
                  ></textarea>
                </div>
                <div className="pt-6">
                  <button
                    style={{ fontSize: "clamp(16px,1.2vw,18px)" }}
                    className="w-full py-3 bg-[#CC890E] hover:bg-transparent transition-all duration-300 ease-in-out hover:text-[#CC890E] border border-[#CC890E] rounded-[24px] text-white font-semibold"
                  >
                    Send Message
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
