const Navbar = {
    links: [
        {
            name: "Home",
            path: "/"
        },
        {
            name: "About",
            path: "/about"
        },
        {
            name: "Services",
            path: "/services"
        }, 
        {
            name: "Contact",
            path: "/contact"
        }
    ]
};

export default Navbar;
