import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

const steps = [
  {
    title: "Bespoke Design Expertise",
    description:"Transform ordinary spaces into extraordinary experiences with our custom-crafted lighting solutions tailored to your unique vision."
  },
  {
    title: "Innovative Technology",
    description:
      "Cutting-edge smart lighting systems that adapt to your lifestyle, combining functionality with futuristic design.",
  },
  {
    title: "Sustainable Illumination",
    description:
      "Eco-friendly solutions that reduce energy consumption without compromising on style or performance.",
  },
  {
    title: "Precision Craftsmanship",
    description:
      "Meticulously designed fixtures created by skilled artisans, ensuring unparalleled quality and attention to detail.",
  },
  {
    title: "Versatile Applications",
    description:
      "From luxurious residential spaces to complex commercial installations, we illuminate every environment with excellence."
  },
  {
    title: "Comprehensive Support",
    description:
      "End-to-end service that guides you from initial concept to final installation, with ongoing expert support.",
  },
];

const ProcessSteps = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  const handleNav = () => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  };
  return (
    <div className="relative min-h-[90vh] flex justify-center items-center py-16">
      <div className="sectionlayout">
        <div
          className="absolute top-0 left-0 w-full h-full bg-cover bg-center"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/Assets/home/luxury_lighting_solutions_dubai.jpg)`,
          }}
        ></div>
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="relative z-10">
          <div className="md:w-1/3 w-full">
            <h1
              className="source-serif font-semibold mb-4 text-white drop-shadow-lg"
              style={{ fontSize: "clamp(30px,2.5vw,46px)" }}
              data-aos="fade-up"
            >
              Lighting Magic Begins
            </h1>

            <p
              style={{ fontSize: "clamp(17px,1vw,20px)" }}
              className="text-white text-justify"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              Imagine spaces transformed by light that tells a story, breathes life, and creates unforgettable atmospheres. We're not just about illumination – we're about turning every room into a canvas of possibility, where design meets innovation and every fixture whispers elegance. Your vision, our expertise, together creating extraordinary moments of pure luminous wonder.
            </p>
          </div>
          <div className="flex flex-col md:flex-row gap-12">
            <div
              className="w-full lg:w-1/3 mt-16 lg:sticky h-[300px] top-[20%]"
              data-aos="fade-right"
            >
              <img
                src={`${process.env.PUBLIC_URL}/Assets/home/outdoor_lighting_design_dubai.png`}
                alt="Dynamic Leo"
                className="rounded-full w-[20rem] h-[20rem] object-cover bottom-[150px]"
              />
            </div>
            <div className="w-full lg:w-2/3">
              <ul className="relative pb-2">
                <div className="w-[2px] bg-white hidden sm:block absolute h-full top-[-20px]"></div>
                {steps.map((step, index) => (
                  <StepItem
                    key={index}
                    title={step.title}
                    description={step.description}
                    index={index}
                  />
                ))}
              </ul>
              <div className="flex mt-4" data-aos="fade-up" data-aos-delay="300">
                <Link to="/contact" onClick={handleNav}>
                  <button className="hover:bg-white hover:text-[#FFD700] transition-all duration-300 ease-in-out bg-transparent border font-medium border-white text-white px-6 py-2.5 md:ml-[-85px]">
                    Start your Project
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const StepItem = ({ title, description, index }) => {
  const { ref, inView } = useInView({ threshold: 1 });

  return (
    <li
      ref={ref}
      className="flex items-start mb-8 relative"

    >
      <div
        className={`w-6 h-6 mt-2 rounded-full z-10 transition-colors duration-300 hidden md:block ${inView ? "bg-[#FFD700]" : "bg-white "
          }`}
        style={{
          minWidth: "24px",
          minHeight: "24px",
          width: "24px",
          height: "24px",
          marginLeft: "-10px",
        }}
      ></div>
      <div className="lg:ml-8 md:ml-6 sm:ml-4 ml-0" data-aos="fade-up" data-aos-offset="50">
        <h2
          className="font-semibold source-serif text-white"
          style={{ fontSize: "clamp(25px,2.5vw,30px)" }}
        >
          {title}
        </h2>
        <p className="text-sm text-white mt-3">{description}</p>
      </div>
    </li>
  );
};

export default ProcessSteps;
