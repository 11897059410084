import React from 'react'

function Social() {
    return (
        <div className='flex flex-row'>
            <a href='https://www.linkedin.com/company/illuminex-lighting-solutions/' target='_blank' rel='noopener noreferrer' className='border rounded-[50%] flex p-3 '>
                <img src={ `${process.env.PUBLIC_URL}/Assets/socialmediaicons/linkedin.svg`} alt="LinkedIn" />
            </a>
            <a href='https://www.instagram.com/illuminexlighting.ae/profilecard/?igsh=MXA2N2QzNTAxZGlh' target='_blank' rel='noopener noreferrer' className='border rounded-[50%] flex p-3'>
                <img src={ `${process.env.PUBLIC_URL}/Assets/socialmediaicons/instagram.svg`} alt="Instagram" />
            </a>
        </div>
    )
}

export default Social;
