import './Navbar.css';
import React from 'react';
import { NavLink } from 'react-router-dom';
import Navbar from '../../Data/Navbar';

function Desktop() {
  const handleNav = () => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  };
  const handleLinkClick = () => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
};

  return (
    <>
      <div className="w-full bg-white hidden lg:block sticky top-0 z-20">
        <div className="sectionlayout flex items-center justify-between poppins text-[#6C6C6C] h-[14vh] border-b">
          <div>
            <NavLink to="/" onClick={handleLinkClick}>
              <img className="max-h-[60px] max-w-[236px] 2xl:h-[100px] 2xl:max-h-[100px]" src={`${process.env.PUBLIC_URL}/Assets/logo/illuminex_lighting_company_uae.jpg`} alt="IlluminexLIGHTING" />
            </NavLink>
          </div>
          <div>
            <div className="flex items-center justify-center hover:cursor-pointer">
              {Navbar.links.map((link, index) =>
                !link.submenu ? (
                  <div key={index}>
                    <NavLink
                      onClick={handleNav}
                      to={link.path}
                      className={({ isActive }) =>
                        `px-5 py-2 rounded-md hover:bg-gray-200 ${isActive ? 'font-bold' : 'text-gray-500'
                        }`
                      }
                    >
                      {link.name}
                    </NavLink>
                  </div>
                ) : (
                  <div key={index} className="relative group flex dropown_menu_trigger">
                    <NavLink
                      onClick={handleNav}
                      to={link.path}
                      className={({ isActive }) =>
                        `px-5 py-2 rounded-md hover:bg-gray-200 flex ${isActive ? 'font-bold' : 'text-gray-500'
                        }`
                      }
                    >
                      {link.name}
                      <div className="flex">
                        <img className="pl-1" src={`${process.env.PUBLIC_URL}/Assets/othericons/dropdown.svg`} alt="dropdown" />
                      </div>
                    </NavLink>
                    <div className="absolute mt-10 dropown_menu z-[100]">
                      <div className="bg-gray-50 font-normal rounded-md w-[15rem] mt-2">
                        {link.submenu.map((sublink, subIndex) => (
                          <div
                            key={subIndex}
                            className="pl-4 pr-8 py-2 hover:bg-gray-200"
                          >
                            <NavLink
                              onClick={handleNav}
                              to={sublink.path}
                              className={({ isActive }) =>
                                isActive ? 'font-bold' : 'text-gray-500'
                              }
                            >
                              {sublink.name}
                            </NavLink>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
         
        </div>
      </div>
    </>
  );
}

export default Desktop;
