import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const VissionMission = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className=" relative min-h-[90vh] flex justify-center items-center py-16">
      <div
        className="absolute top-0 left-0 w-full h-full bg-cover bg-center"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/Assets/home/premium_lighting_design_dubai_hima.jpg)`,
        }}
      ></div>

      <div className="absolute inset-0 bg-black bg-opacity-50"></div>

      <div
        className="relative mx-auto w-full z-10 text-center text-[#F8F5F0] space-y-8"
        data-aos="fade-up"
      >
        <div className="space-y-7 w-full mx-auto py-6 px-4 bg-black bg-opacity-40  shadow-lg">
          <h1
            className=" font-semibold drop-shadow-lg "
            style={{ fontSize: "clamp(32px,2.5vw,46px)" }}
          >
            Our Illuminous Vision
          </h1>
          <p className="text-lg text-justify leading-relaxed mx-auto md:w-[75%]">
          At Illuminex Lighting, we dream of illuminating the entire UAE with our innovative, sustainable, and luxurious lighting solutions. From the iconic Burj Khalifa to the vast expanse of the Arabian Desert, we aspire to transform spaces and elevate experiences across the nation. With our custom-designed LED chandeliers, modern fixtures, and passion for exceptional lighting, we aim to redefine the way the UAE shines, one space at a time.
          </p>
        </div>

        <div className="space-y-7 w-full mx-auto py-6 px-4 bg-black bg-opacity-40   shadow-lg">
          <h1
            className=" font-semibold drop-shadow-lg"
            style={{ fontSize: "clamp(32px,2.5vw,46px)" }}
          >
            Our Vibrant Mission 
          </h1>
          <p className="text-lg text-justify md:text-center leading-relaxed mx-auto md:w-[75%]">
          To deliver exceptional lighting solutions that transform environments, combining innovative design, premium quality, and energy-efficient technologies. We are committed to creating extraordinary lighting experiences that enhance the aesthetic and functional value of residential and commercial spaces in Dubai, while supporting global sustainability efforts.
          </p>
        </div>
      </div>
    </div>
  );
};

export default VissionMission;
