import React from 'react'
export default function ContactForm() {

  return (
    <div className="sectionlayout overflow-x-hidden">
      <div className="flex my-24 flex-col md:flex-row bg-white  space-y-6 md:space-y-0 md:space-x-10">
        <div className="md:w-1/2 space-y-4" data-aos="fade-right">
          <h2
            className={`text-[#FFD700] text-2xl font-bold source-serif`}
            style={{ fontSize: "clamp(34px,2vw,40px)" }}
          >
            Have a project?
          </h2>
          <h3
            className="text-2xl font-semibold source-serif"
            style={{ fontSize: "clamp(34px,2vw,40px)" }}
          >
            Let's discuss
          </h3>
          <p className="text-[#1e1e1e] md:w-[70%] ">
            Once you submit this form, expect to hear back from us within 24
            hours.
          </p>
               {/* Phone Section */}
               <div className="w-full flex items-center pt-2">
                <img src={`${process.env.PUBLIC_URL}/Assets/socialmediaicons/phone.svg`} alt="Phone Icon" className="w-[1.7rem]" />
                <a href="tel:+971509969258" className="pl-2 md:pl-4 pt-2 text-black text-[17px] md:text-[20px] hover:underline">
                  +971 50 996 9258
                </a>
              </div>
              <div className="w-full flex items-center pt-0">
                <img src={`${process.env.PUBLIC_URL}/Assets/socialmediaicons/phone.svg`} alt="Phone Icon" className="w-[1.7rem] opacity-0" />
                <a href="tel:+971506367409" className="pl-2 md:pl-4 text-black text-[17px] md:text-[20px] hover:underline">
                  +971 50 636 7409
                </a>
              </div>

              {/* Email Section */}
              <div className="w-full flex items-center pt-1">
                <img src={`${process.env.PUBLIC_URL}/Assets/socialmediaicons/email.svg`} alt="Email" className="w-[1.7rem]" />
                <a
                  href="https://mail.google.com/mail/?view=cm&fs=1&to=info@illuminexlighting.ae&su=Inquiry&body=Hello%20Illuminex%20Lighting%20Team,"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="pl-2 md:pl-4 pt-2 text-black text-[17px] md:text-[20px] hover:underline"
                >
                  info@illuminexlighting.ae
                </a>
              </div>
        </div>
        <form className="md:w-1/2 space-y-4" data-aos="fade-left">
          <input
            type="text"
            placeholder="Name"
            className={`w-full border border-gray-300 transition-all duration-300 ease-in-out p-3 rounded-md focus:border-none focus:outline-none focus:ring-2 focus:ring-[#FFD700]`}
          />
          <div className="flex sm:flex-row flex-col sm:space-x-4">
            <input
              type="text"
              placeholder="Phone"
              className={`w-full sm:w-1/2 border border-gray-300 transition-all duration-300 ease-in-out focus:border-none p-3 rounded-md focus:outline-none focus:ring-2 focus:ring-[#FFD700]`}
            />
            <input
              type="text"
              placeholder="Company"
              className={`w-full mt-4 sm:mt-0 sm:w-1/2 border border-gray-300 transition-all duration-300 ease-in-out p-3 rounded-md focus:border-none focus:outline-none focus:ring-2 focus:ring-[#FFD700]`}
            />
          </div>
          <input
            type="text"
            placeholder="How did you hear about us?"
            className={`w-full border border-gray-300 transition-all duration-300 ease-in-out p-3 rounded-md focus:border-none focus:outline-none focus:ring-2 focus:ring-[#FFD700]`}
          />
          <textarea
            placeholder="How can we help you?"
            className={`w-full border border-gray-300 transition-all duration-300 ease-in-out p-3 rounded-md focus:border-none focus:outline-none focus:ring-2 focus:ring-[#FFD700] h-32 resize-none`}
          ></textarea>
          <button
            type="submit"
            className={`bg-[#FFD700] text-white font-semibold py-2.5 px-6 transition-all duration-300 ease-in-out border-2 border-[#FFD700]  hover:bg-transparent hover:text-[#FFD700] focus:outline-none focus:ring-2 focus:ring-[#FFD700]`}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}
