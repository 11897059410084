import React from 'react'
import HeaderWithBackgroundImage from './Hero'
import ProcessSteps from './ProcessSteps'
import VissionMission from './VisionMission'
import { Helmet } from 'react-helmet'

function Home() {
  return (
    <>
      <Helmet>
        <title>Illuminex UAE | Premium Lighting Solutions Dubai | Top Lighting Company</title>
        <meta name="description" content="Leading lighting solutions provider in UAE specializing in architectural, facade, urban & smart control systems. Transform your space with expert lighting design." />
        <meta name="keywords" content="premium lighting solutions dubai, illuminex uae, lighting company dubai, architectural lighting, facade lighting, urban lighting, smart control systems, lighting design uae" />        
      </Helmet>
      
      <div>
        <HeaderWithBackgroundImage />
        <ProcessSteps />
        <VissionMission />
      </div>
    </>
  )
}

export default Home